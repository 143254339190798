import { faCaretLeft as showIcon } from "@fortawesome/free-solid-svg-icons/faCaretLeft";
import { faCaretRight as hideIcon } from "@fortawesome/free-solid-svg-icons/faCaretRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { collection, query, where } from "firebase/firestore";
import React, { useRef, useState } from "react";
import { firestore } from "../../firebase";
import { useAuth } from "../auth/useAuth";
import ChatInput from "../chat/ChatInput";
import ChatList from "../chat/ChatList";
import PinnedChatList from "../chat/PinnedChatList";

const getMessagesCollectionForTripRequest = (tripRequestId) => collection(firestore, "tripRequestsChat", tripRequestId, "messages");

const getPinnedMessagesForTripRequest = (tripRequestId) => query(getMessagesCollectionForTripRequest(tripRequestId), where("pinned", "==", true));

const TripRequestPinnedItems = ({
  rightUserId,
  lastReadTime,
  tripRequestId,
  editedMessage,
  setEditedMessage,
  userChatInputRef,
  userChatInputText,
  infiniteRef,
}) => {
  return (
    <div className="triprequestchat-pinned-items">
      <div className="hidden md:inline text-l font-bold pt-2 pb-1 w-fit font-serif flex-none text-left mx-3 border-b border-dividerColor">
        Pinned Items
      </div>
      <PinnedChatList
        rightUserId={rightUserId}
        editOthers={false}
        pinnedMessagesCollection={getPinnedMessagesForTripRequest(tripRequestId)}
        lastReadTime={lastReadTime}
        editedMessage={editedMessage}
        setEditedMessage={setEditedMessage}
        userChatInputRef={userChatInputRef}
        userChatInputText={userChatInputText}
        infiniteRef={infiniteRef}
      />
    </div>
  );
};

export const TripRequestChat = ({ tripRequestId }) => {
  // TODO: We currently do not track last read time on the web (to see where the person stopped reading a chat message).
  // TODO: We need to ensure that we track the scrolling position and update the lastReadTime accordingly in firebase if we want for the read indicator to be accurate.
  const lastReadTime = new Date();
  const infiniteRef = useRef(null);

  const [editedMessage, setEditedMessage] = useState(null);
  const [userChatInputText, setUserChatInputText] = useState("");
  const userChatInputRef = useRef();
  const auth = useAuth();
  const [notepadShown, setNotepadShown] = useState(true);
  const handleClick = () => {
    setNotepadShown(!notepadShown);
  };

  if (!notepadShown) {
    return (
      <div className="triprequestchat-hidden text-2xl">
        <button
          type="button"
          className="w-8 h-8 my-1 text-black hover:text-white bg-regularButtonBackgroundColor hover:bg-mainTintColor  border-dividerColor border rounded-l-md flex items-center justify-center"
          onClick={handleClick}
        >
          <FontAwesomeIcon icon={showIcon} fixedWidth />
        </button>
      </div>
    );
  }
  return (
    <div className="triprequestchat-rightbar border-l border-dividerColor">
      <div className="hidden md:flex text-2xl font-extrabold pt-3 pb-1 font-serif flex-row text-left border-b border-dividerColor border-opacity-50">
        <button
          type="button"
          className="w-8 h-8 my-1 text-black hover:text-white bg-regularButtonBackgroundColor hover:bg-mainTintColor border-dividerColor border-t border-r border-b rounded-r-md mr-3 flex items-center justify-center"
          onClick={handleClick}
        >
          <FontAwesomeIcon icon={hideIcon} fixedWidth />
        </button>
        <div>Notepad</div>
      </div>
      <TripRequestPinnedItems
        tripRequestId={tripRequestId}
        rightUserId={auth.userId}
        editOthers={false}
        lastReadTime={lastReadTime}
        editedMessage={editedMessage}
        setEditedMessage={setEditedMessage}
        userChatInputRef={userChatInputRef}
        userChatInputText={userChatInputText}
        infiniteRef={infiniteRef}
      />
      <div
        className="flex-grow px-3 pt-3 pb-6 overflow-y-auto h-full"
        ref={infiniteRef}
      >
        <ChatList
          rightUserId={auth.userId}
          editOthers={false}
          canBePinned={true}
          messagesCollection={getMessagesCollectionForTripRequest(tripRequestId)}
          lastReadTime={lastReadTime}
          editedMessage={editedMessage}
          setEditedMessage={setEditedMessage}
          userChatInputRef={userChatInputRef}
          userChatInputText={userChatInputText}
          infiniteRef={infiniteRef}
        />
      </div>
      <ChatInput
        className="mx-3 mb-3"
        messagesCollection={getMessagesCollectionForTripRequest(tripRequestId)}
        editedMessage={editedMessage}
        setEditedMessage={setEditedMessage}
        userChatInputRef={userChatInputRef}
        userChatInputText={userChatInputText}
        setUserChatInputText={setUserChatInputText}
      />
    </div>
  );
};
