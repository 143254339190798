import { limit, onSnapshot, orderBy, query, startAfter } from "firebase/firestore";
import { useEffect, useReducer, useRef } from "react";

const findIndexOfDocument = (doc, items) => items.findIndex(item => item.id === doc.id);

const updateItem = (doc, items) => {
  const i = findIndexOfDocument(doc, items);
  items[i] = doc;
};

const deleteItem = (doc, items) => {
  const i = findIndexOfDocument(doc, items);
  items.splice(i, 1);
};

const addItem = (doc, items, isNew) => {
  const i = findIndexOfDocument(doc, items);
  if (i === -1) {
    isNew ? items.push(doc) : items.unshift(doc);
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "loaded": {
      const items = [...state.items];
      let isAdding = false;
      let isNew = false;

      for (const change of action.value.docChanges()) {
        if (change.type === "added") {
          isAdding = true;
          isNew = change.oldIndex === -1 && change.newIndex === 0;
          addItem(change.doc, items, isNew);
        } else if (change.type === "modified") {
          updateItem(change.doc, items);
        } else if (change.type === "removed") {
          deleteItem(change.doc, items);
        }
      }

      const nextLimit = items.length + action.pageSize;
      const end = items.length < action.limit || nextLimit === state.limit;

      return {
        ...state,
        hasMore: isAdding && !isNew ? !end : state.hasMore,
        limit: nextLimit,
        loading: false,
        loadingError: null,
        lastLoaded: action.value.docs[action.value.docs.length - 1],
        loadingMore: false,
        items,
      };
    }

    case "loadMore": {
      return {
        ...state,
        loadingMore: true,
        after: state.lastLoaded,
      };
    }

    default:
      throw new Error("Unknown Case");
  }
};

const initialState = {
  hasMore: false,
  after: null,
  limit: 0,
  items: [],
  lastLoaded: null,
  loading: true,
  loadingError: null,
  loadingMore: false,
  loadingMoreError: null,
};

export const usePaginatedQuery = (baseQuery, { firstPage = 50, pageSize = 50, includeMetadataChanges = false }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const baseQueryRef = useRef(baseQuery);

  useEffect(() => {
    let unsubscribe;

    const fetchData = async () => {
      try {
        let queryRef = query(
          baseQueryRef.current,
          orderBy("time.date", "desc"),
          limit(state.limit || firstPage),
        );

        if (state.after) {
          queryRef = query(queryRef, startAfter(state.after));
        }

        unsubscribe = onSnapshot(
          queryRef,
          { includeMetadataChanges },
          (snap) => dispatch({ type: "loaded", value: snap, pageSize }),
          (error) => dispatch({ type: "loadingError", error }),
        );
      } catch (error) {
        dispatch({ type: "loadingError", error });
      }
    };

    fetchData();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [state.after, firstPage, pageSize, includeMetadataChanges, baseQueryRef]);

  return {
    after: state.after,
    lastLoaded: state.lastLoaded,
    loadingMore: state.loadingMore,
    loadingError: state.loadingError,
    loadingMoreError: state.loadingMoreError,
    loading: state.loading,
    hasMore: state.hasMore,
    items: state.items,
    loadMore: () => dispatch({ type: "loadMore" }),
  };
};
